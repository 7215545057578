import React from 'react';

import { styled } from '../../stitches.config';
import { DynamicVariantProp, MediaQueryKeys } from '../../types';
import { extractCssProps } from '../../util/css/stitches';

const GridItemStitched = styled('div', {});

const sparkyClassName = 'sparky-grid-item';

export const GridItem = React.forwardRef<HTMLDivElement, React.PropsWithChildren<GridItemProps>>(
  ({ children, as = 'div', className = '', ...props }, ref) => {
    const cssProps = extractCssProps(props);
    return (
      <GridItemStitched css={cssProps} as={as} className={`${sparkyClassName} ${className}`} ref={ref}>
        {children}
      </GridItemStitched>
    );
  },
);

GridItem.toString = () => `.${sparkyClassName}`;

GridItemStitched.displayName = 'styled(Grid.Item)';
GridItem.displayName = 'Grid.Item';

interface GridItemProps {
  /** This is the [native grid-area CSS property](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area) that will be directly passed on to the grid item.   */
  gridArea?: DynamicVariantProp<'gridArea'>;

  /** This is the [native grid-column-start CSS property](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column-start) that will be directly passed on to the grid item and correlates to the `PageGrid` column count. */
  gridColumnStart?: GridColumnSingleOrResponsive;

  /** This is the [native grid-column-end CSS property](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column-end) that will be directly passed on to the grid item and correlates to the `PageGrid` column count. */
  gridColumnEnd?: GridColumnSingleOrResponsive;

  /** This is the [native grid-column CSS property](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column) that will be directly passed on to the grid item and correlates to the `PageGrid` column count. */
  gridColumn?: GridColumnShortHandSingleOrResponsive;

  /** This is the [native grid-row-start CSS property](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-row-start) that will be directly passed on to the grid item.   */
  gridRowStart?: DynamicVariantProp<'gridRowStart'>;

  /** This is the [native grid-row-end CSS property](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-row-end) that will be directly passed on to the grid item.   */
  gridRowEnd?: DynamicVariantProp<'gridRowEnd'>;

  /** This is the [native grid-row CSS property](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-row) that will be directly passed on to the grid item.   */
  gridRow?: DynamicVariantProp<'gridRow'>;

  /** This is the [native order CSS property](https://developer.mozilla.org/en-US/docs/Web/CSS/order) that will be directly passed on to the grid item.   */
  order?: DynamicVariantProp<'order'>;

  /** The DOM element to render */
  as?: keyof JSX.IntrinsicElements;
  className?: never;
}

type ColumnsMobile = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type ColumnsDesktop = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13';

type ColumnsAllowed<Cols extends string> =
  | Cols
  | `-${Cols}`
  | `${Cols | `-${Cols}`}/${Cols | `-${Cols}`}`
  | `${Cols | `-${Cols}`}/span ${Cols | `-${Cols}`}`;

type MediaQueryGridColumn<M extends string, D extends string> = {
  [key in MediaQueryKeys | 'initial']?: key extends 'initial' | 'sm' ? M : D;
};

type GridColumnShortHandSingleOrResponsive =
  | ColumnsAllowed<ColumnsDesktop>
  | MediaQueryGridColumn<ColumnsAllowed<ColumnsMobile>, ColumnsAllowed<ColumnsDesktop>>;

type GridColumnSingleOrResponsive =
  | ColumnsAllowed<ColumnsDesktop>
  | MediaQueryGridColumn<ColumnsAllowed<ColumnsMobile>, ColumnsAllowed<ColumnsDesktop>>;
