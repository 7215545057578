import React from 'react';

import { StackItem } from './StackItem';
import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';

export const StackStyled = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',

  variants: {
    /** `direction` will decide if the children will be layed out in a `row` or in a `column`. */
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
    /** `gap` is the space between children. */
    gap: {
      0: { marginTop: 0, marginLeft: 0 },
      1: { gap: '$1', '.no-flexbox-gap &': { noflexgap: '$1' } },
      2: { gap: '$2', '.no-flexbox-gap &': { noflexgap: '$2' } },
      3: { gap: '$3', '.no-flexbox-gap &': { noflexgap: '$3' } },
      4: { gap: '$4', '.no-flexbox-gap &': { noflexgap: '$4' } },
      5: { gap: '$5', '.no-flexbox-gap &': { noflexgap: '$5' } },
      6: { gap: '$6', '.no-flexbox-gap &': { noflexgap: '$6' } },
      7: { gap: '$7', '.no-flexbox-gap &': { noflexgap: '$7' } },
      8: { gap: '$8', '.no-flexbox-gap &': { noflexgap: '$8' } },
      10: { gap: '$10', '.no-flexbox-gap &': { noflexgap: '$10' } },
      12: { gap: '$12', '.no-flexbox-gap &': { noflexgap: '$12' } },
      16: { gap: '$16', '.no-flexbox-gap &': { noflexgap: '$16' } },
    },
    /** `wrap` causes the Stack to become multi-line if there's not enough space. */
    wrap: {
      true: {
        flexWrap: 'wrap',
      },
      false: {
        flexWrap: 'nowrap',
      },
    },

    /** `alignX` causes items to align to the horizontal axis */
    alignX: {
      start: {},
      center: {},
      end: {},
      justify: {
        justifyContent: 'space-between',
      },
    },
    /** `alignY` causes items to align to the vertical axis */
    alignY: {
      start: {},
      center: {},
      end: {},
      justify: {
        justifyContent: 'space-between',
      },
    },
    inline: {
      true: { display: 'inline-flex' },
      false: { display: 'flex' },
    },
  },
  compoundVariants: [
    // alignX
    {
      direction: 'row',
      alignX: 'start',
      css: {
        justifyContent: 'flex-start',
      },
    },
    {
      direction: 'column',
      alignX: 'start',
      css: {
        alignItems: 'flex-start',
      },
    },
    {
      direction: 'row',
      alignX: 'center',
      css: {
        justifyContent: 'center',
      },
    },
    {
      direction: 'column',
      alignX: 'center',
      css: {
        alignItems: 'center',
      },
    },
    {
      direction: 'row',
      alignX: 'end',
      css: {
        justifyContent: 'flex-end',
      },
    },
    {
      direction: 'column',
      alignX: 'end',
      css: {
        alignItems: 'flex-end',
      },
    },
    /** AlignY  */
    {
      direction: 'row',
      alignY: 'start',
      css: {
        alignItems: 'flex-start',
      },
    },
    {
      direction: 'column',
      alignY: 'start',
      css: {
        justifyContent: 'flex-start',
      },
    },
    {
      direction: 'row',
      alignY: 'center',
      css: {
        alignItems: 'center',
      },
    },
    {
      direction: 'column',
      alignY: 'center',
      css: {
        justifyContent: 'center',
      },
    },
    {
      direction: 'row',
      alignY: 'end',
      css: {
        alignItems: 'flex-end',
      },
    },
    {
      direction: 'column',
      alignY: 'end',
      css: {
        justifyContent: 'flex-end',
      },
    },
  ],
  defaultVariants: {
    gap: '0',
    direction: 'column',
  },
});

const stitchesClassName = 'sparky-stack';

const StackComponent = React.forwardRef<HTMLDivElement, Props>(
  ({ children, as = 'div', className = '', ...props }, ref) => {
    const variantProps = extractVariantProps(props);

    // Only add a div wrapper when we nest a Stack or give a text node as a child, to apply the correct margins
    const augmentedChildren = React.Children.toArray(children)
      .filter(React.isValidElement)
      .map(child => {
        if (typeof child === 'string' || typeof child === 'number') return <div key={child}>{child}</div>;

        const isNestedStack = child.type.toString() === `.${stitchesClassName}`;
        return isNestedStack ? React.createElement('div', { key: child.key }, child) : child;
      });

    return (
      <StackStyled ref={ref} {...variantProps} as={as} className={`${className} ${stitchesClassName}`}>
        {augmentedChildren}
      </StackStyled>
    );
  },
);

StackComponent.toString = () => `.${stitchesClassName}`;

type StackVariants = TransformStitchesToSparky<typeof StackStyled>;

interface Props extends StackVariants {
  as?: keyof JSX.IntrinsicElements;
  /** `className` is for internal use only */
  className?: never;
  children?: React.ReactNode;
}

// Compound components with forwardRef cause issues: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
export const Stack = Object.assign({}, StackComponent, { Item: StackItem });

StackStyled.displayName = 'styled(Stack)';
Stack.Item.displayName = 'Stack.Item';
StackComponent.displayName = 'Stack';
