import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';

import reactToText from 'react-to-text';

import { ButtonRecipeVariants, buttonRecipe, isLoadingStyle } from './Button.css';
import { ButtonInner } from './ButtonInner';
import { SpinnerIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { getClassNames } from '../../util/css';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

export const sparkyButtonClassName = 'sparky-button';

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      action = 'primary',
      children,
      className = '',
      finishedLoadingText,
      form,
      isLoading,
      loadingText,
      onClick,
      size = 'regular',
      tone = 'onLight',
      type = 'button',
    },
    ref,
  ) => {
    const { loading, finishedLoading } = useI18nTranslations();

    const [helperText, setHelperText] = useState('');

    useEffect(() => {
      if (!isLoading && helperText !== '') {
        setHelperText(finishedLoadingText ?? finishedLoading);
      }
      if (isLoading) {
        setHelperText(loadingText ?? loading);
      }
    }, [isLoading, helperText, loadingText, finishedLoadingText, loading, finishedLoading]);

    const classNames = getClassNames(
      sparkyButtonClassName,
      buttonRecipe({ action, size, tone }),
      isLoading && isLoadingStyle,
      className,
    );

    return (
      <>
        <button
          aria-disabled={isLoading}
          className={classNames}
          data-label={reactToText(children)}
          data-variant={action} // necessary for component_variant event tracking
          form={form}
          onClick={onClick}
          ref={ref}
          type={isLoading ? 'button' : type}>
          <ButtonInner aria-hidden={isLoading}>{children}</ButtonInner>
          {isLoading ? (
            <>
              <SpinnerIcon /> <VisuallyHidden>{helperText}</VisuallyHidden>
            </>
          ) : null}
        </button>
        {!isLoading ? <VisuallyHidden ariaLive="polite">{helperText}</VisuallyHidden> : null}
      </>
    );
  },
);

export { ButtonInner };

Button.toString = () => `.${sparkyButtonClassName}`;
Button.displayName = 'Button';

export type ButtonProps = ButtonRecipeVariants &
  Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'form' | 'onClick'> & {
    className?: never;
    children: React.ReactNode;
    isDisabled?: boolean;
    isLoading?: boolean;
    /** Hidden helper text for screenreaders when the button is in the loading state */
    loadingText?: string;
    /** Hidden helper text for screenreaders when the button is no longer in the loading state */
    finishedLoadingText?: string;
  };
