import React, { FC, PropsWithChildren } from 'react';

import { keyframes, styled } from '../../stitches.config';
import { DynamicVariantProp, StitchesVariants } from '../../types';
import { extractCssProps, extractVariantProps } from '../../util/css/stitches';

const SkeletonAnimation = keyframes({
  from: {
    opacity: '1',
  },
  to: {
    opacity: '$opacity50',
  },
});

const StyledSkeleton = styled('div', {
  $$animationDuration: '0.01ms',
  '@safeMotion': {
    $$animationDuration: '.5s',
  },
  animationName: `${SkeletonAnimation}`,
  animationDuration: '$$animationDuration',
  animationTimingFunction: 'ease-out',
  animationDirection: 'alternate',
  animationIterationCount: 'infinite',

  borderRadius: '$m',
  background: '$backgroundTertiary',
  variants: {
    variant: {
      circle: {
        borderRadius: '$round',
      },
      rectangular: {
        borderRadius: '$none',
      },
      text: {
        height: 'auto',
        transform: 'scale(1, 0.8)',
        '&:empty::before': {
          content: '.',
          visibility: 'hidden',
        },
      },
    },
    hasChildren: {
      true: {
        maxWidth: 'fit-content',
      },
    },
  },
});

const SkeletonChild = styled('span', {
  visibility: 'hidden',
});

const stitchesClassName = 'sparky-skeleton';

export const Skeleton: FC<PropsWithChildren<Props>> = ({
  variant = 'text',
  width,
  height,
  className = '',
  children,
}) => {
  const variantProps = extractVariantProps({ variant });
  const cssProps = extractCssProps({ width, height });

  return (
    <StyledSkeleton
      {...variantProps}
      className={`${stitchesClassName} ${className}`}
      css={cssProps}
      hasChildren={!!children}>
      {children ? <SkeletonChild aria-hidden>{children}</SkeletonChild> : null}
    </StyledSkeleton>
  );
};

Skeleton.toString = () => `.${stitchesClassName}`;
SkeletonChild.displayName = 'SkeletonChild';

type SkeletonTypes = StitchesVariants<typeof StyledSkeleton>['variant'];

interface Props {
  variant?: SkeletonTypes;
  width?: DynamicVariantProp<'width'>;
  height?: DynamicVariantProp<'height'>;
  className?: never;
}
