import React from 'react';

import { GridItem } from './GridItem';
import { styled } from '../../stitches.config';
import { TransformStitchesToSparky, DynamicVariantProp } from '../../types';
import { extractCssProps } from '../../util/css/stitches';

/* (Readonly) Source of truth for the PageGrid. Convenient if we need this in other components  */
export const gridData = {
  columns: {
    initial: 9,
    md: 12,
  },
  gaps: {
    initial: '$2',
    md: '$3',
    lg: '$4',
    xl: '$6',
  },
  gutters: {
    initial: '$6',
    md: '$10',
    xl: '$24',
  },
  maxWidth: {
    initial: '100vw',
    xl: '1440px',
  },
} as const;

export const PageGridStitched = styled('div', {
  $$columns: gridData.columns.initial,
  gap: gridData.gaps.initial,
  paddingX: gridData.gutters.initial,
  display: 'grid',
  gridTemplateColumns: 'repeat($$columns, 1fr)',
  marginLeft: 'auto',
  marginRight: 'auto',

  '@md': {
    $$columns: gridData.columns.md,
    gap: gridData.gaps.md,
    paddingX: gridData.gutters.md,
  },
  '@lg': {
    gap: gridData.gaps.lg,
  },
  '@xl': {
    maxWidth: gridData.maxWidth.xl,
    gap: gridData.gaps.xl,
    paddingX: gridData.gutters.xl,
  },
});

const stitchesClassName = 'sparky-base-grid';

/** The `PageGrid` component is *only* used for page (layout) grids */
export const PageGridComponent = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Partial<Props>>>(
  ({ children, as = 'div', className = '', ...props }, ref) => {
    const cssProps = extractCssProps(props);
    return (
      <PageGridStitched css={cssProps} as={as} ref={ref} className={`${stitchesClassName} ${className}`}>
        {children}
      </PageGridStitched>
    );
  },
);

PageGridComponent.toString = () => `.${stitchesClassName}`;
PageGridStitched.displayName = 'styled(PageGrid)';
PageGridComponent.displayName = 'PageGrid';

type GridVariants = TransformStitchesToSparky<typeof PageGridStitched>;
export interface Props extends GridVariants {
  /** This is the [native grid-template-areas CSS property](https://www.w3schools.com/cssref/pr_grid-template-areas.asp) that will be directly passed on to the grid. */
  gridTemplateAreas: DynamicVariantProp<'gridTemplateAreas'>;

  /** This is the [native grid-template-rows CSS property](https://www.w3schools.com/cssref/pr_grid-template-rows.asp) that will be directly passed on to the grid.  */
  gridTemplateRows?: DynamicVariantProp<'gridTemplateRows'>;

  /** The DOM element to render */
  as?: Extract<keyof JSX.IntrinsicElements, 'div' | 'article'>;
  className?: never;
}

export const PageGrid = Object.assign({}, PageGridComponent, { Item: GridItem });
