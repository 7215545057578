import React, { AnchorHTMLAttributes } from 'react';

import reactToText from 'react-to-text';

import { getClassNames } from '../../util/css';
import { ButtonInner } from '../Button/Button';
import { ButtonRecipeVariants, buttonRecipe } from '../Button/Button.css';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

const sparkyButtonLinkClassName = 'sparky-buttonlink';

export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    { children, label, className = '', onClick, href, target, action = 'primary', tone = 'onLight', size = 'regular' },
    ref,
  ) => {
    const classNames = getClassNames(sparkyButtonLinkClassName, buttonRecipe({ action, size, tone }), className);

    return (
      <a
        className={classNames}
        data-label={label ?? reactToText(children)}
        href={href}
        onClick={onClick}
        ref={ref}
        {...(target ? { target } : null)}>
        {label ? <VisuallyHidden>{label}</VisuallyHidden> : null}
        <ButtonInner>{children}</ButtonInner>
      </a>
    );
  },
);

ButtonLink.toString = () => `.${sparkyButtonLinkClassName}`;
ButtonLink.displayName = 'ButtonLink';

type ButtonLinkProps = ButtonRecipeVariants &
  Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick' | 'target'> & {
    className?: never;
    children: React.ReactNode;
    label?: string;
  };
