import React, { ForwardedRef, forwardRef, ReactNode, SyntheticEvent } from 'react';

import { Icon } from '../../icons/IconWrapper';
import { styled } from '../../stitches.config';
import { extractVariantProps } from '../../util/css/stitches';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

export const buttonStyle = {
  appearance: 'none',
  cursor: 'pointer',
  color: 'inherit',
  fontWeight: '$bodyBold',
  border: 0,
  borderRadius: '$round',
  backgroundColor: 'transparent',
  textDecoration: 'none',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '-webkit-tap-highlight-color': 'transparent',
  '@safeMotion': {
    transition: 'background-color $easeQuick',
  },
  variants: {
    isCurrent: {
      true: {
        color: '$colors$linkBrand',
        [`& ${Icon}`]: { fill: '$colors$linkBrand' },
        textDecoration: 'underline',
        textDecorationThickness: '$borderWidths$m',
        'text-underline-offset': '6px',
      },
    },
    color: {
      primary: {
        [`& ${Icon}`]: { fill: '$iconTertiary' },
      },
      secondary: {
        [`& ${Icon}`]: { fill: '$iconSecondary' },
      },
      inverted: {
        [`& ${Icon}`]: { fill: '$iconInverted' },
      },
    },
    isDisabled: {
      true: {
        opacity: '$opacity50',
        cursor: 'not-allowed',
      },
      false: {},
    },

    size: {
      regular: {
        minWidth: '$targetMinWidth',
        minHeight: '$targetMinHeight',
      },

      large: {
        minWidth: '48px',
        minHeight: '48px',
      },
    },
  },

  compoundVariants: [
    {
      // should apply to all (but inverted) colors
      isDisabled: false,
      css: {
        '&:focus-visible': {
          outline: '$outlineFocus',
          backgroundColor: '$neutral300',
        },
        '@supports not selector(:focus-visible)': {
          '&:focus': {
            outline: '$outlineFocus',
            backgroundColor: '$neutral300',
          },
        },
        '&:hover': {
          backgroundColor: '$backgroundTertiary',
        },
        '&:active': {
          backgroundColor: '$neutral300',
        },
      },
    },
    {
      color: 'inverted',
      isDisabled: false,
      css: {
        '&:focus-visible': {
          outline: '$borderWidths$m solid $colors$neutralWhite',
          backgroundColor: '$whiteOpacity30',
        },
        '@supports not selector(:focus-visible)': {
          '&:focus': {
            outline: '$borderWidths$m solid $colors$neutralWhite',
            backgroundColor: '$whiteOpacity30',
          },
        },
        '&:hover': {
          backgroundColor: '$whiteOpacity15',
        },
        '&:active': {
          backgroundColor: '$whiteOpacity30',
        },
      },
    },
  ],
  defaultVariants: {
    color: 'primary',
    isDisabled: 'false',
    size: 'regular',
  },
};

const StyledButton = styled('button', buttonStyle);
const StyledAnchor = styled('a', buttonStyle);

type ButtonProps = {
  href?: never;
  target?: never;
  type?: 'submit' | 'button';
  isCurrent?: never;
  rel?: never;
};

type AnchorProps = {
  href?: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  type?: never;
  isCurrent?: boolean;
  rel?: string;
};

type IconButtonProps = {
  label: string;
  href?: string;
  isDisabled?: boolean;
  onClick?: (event: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  size?: 'regular' | 'large';
  color?: 'primary' | 'secondary' | 'inverted';
  children: JSX.Element | ReactNode;
} & (ButtonProps | AnchorProps);

export const IconButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, IconButtonProps>(
  (
    { label, children, href, target, onClick, type = 'button', isDisabled = false, color, rel, size, isCurrent },
    ref,
  ) => {
    const variantProps = extractVariantProps({ color, isDisabled, size, isCurrent });
    if (href) {
      return (
        <StyledAnchor
          data-label={label}
          href={href}
          onClick={onClick}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          rel={rel}
          aria-current={isCurrent ? 'page' : 'false'}
          {...(target ? { target } : null)}
          {...variantProps}>
          <VisuallyHidden>{label}</VisuallyHidden>
          {children}
        </StyledAnchor>
      );
    }

    return (
      <StyledButton
        data-label={label}
        disabled={isDisabled}
        onClick={onClick}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        type={type}
        {...variantProps}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </StyledButton>
    );
  },
);
