import { FC } from 'react';

import { styled } from '../../stitches.config';
import { textColors } from '../../themes/eneco/tokens';
import { TransformStitchesToSparky } from '../../types';
import { extractDataProps } from '../../util/attributes';
import { extractVariantProps, createVariants } from '../../util/css/stitches';

export const StyledText = styled('span', {
  display: 'block',

  '& > &': { display: 'inline' },

  variants: {
    /** Color of the text */
    color: createVariants(textColors, 'color'),

    /** Alignment of the text  */
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },

    display: {
      block: { display: 'block' },
      inline: { display: 'inline' },
    },

    /** Size of the text */
    size: {
      BodyXL: { typography: '$bodyXL' },
      BodyL: { typography: '$bodyL' },
      BodyM: { typography: '$bodyM' },
      BodyS: { typography: '$bodyS' },
      BodyXS: { typography: '$bodyXS' },
      QuoteM: { typography: '$quoteM' },
      QuoteS: { typography: '$quoteS' },
    },

    /** Weight of the text */
    weight: {
      regular: {
        fontWeight: '$bodyRegular',
      },
      bold: {
        fontWeight: '$bodyBold',
      },
    },
    whiteSpace: {
      normal: {
        whiteSpace: 'normal',
      },
      nowrap: {
        whiteSpace: 'nowrap',
      },
      pre: {
        whiteSpace: 'pre',
      },
      'pre-wrap': {
        whiteSpace: 'pre-wrap',
      },
      'pre-line': {
        whiteSpace: 'pre-line',
      },
      'break-spaces': {
        whiteSpace: 'break-spaces',
      },
    },
  },
});

const stitchesClassName = 'sparky-text';

export const Text: FC<TextProps> = ({
  align,
  'aria-hidden': ariaHidden,
  as = 'span',
  children,
  className = '',
  color,
  dangerouslySetInnerHTML,
  display,
  id,
  size,
  weight,
  whiteSpace,
  ...rest
}) => {
  const dataSet = extractDataProps(rest);
  const variantProps = extractVariantProps({ weight, size, color, align, whiteSpace, display });

  return (
    <StyledText
      {...dataSet}
      {...variantProps}
      aria-hidden={ariaHidden}
      as={as}
      className={`${stitchesClassName} ${className}`}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      id={id}>
      {children}
    </StyledText>
  );
};

Text.toString = () => `.${stitchesClassName}`;

StyledText.displayName = 'styled(Text)';

type TextVariants = TransformStitchesToSparky<typeof StyledText>;
interface TextProps extends TextVariants {
  children?: React.ReactNode;
  /** The DOM element to render */
  as?: Extract<
    keyof JSX.IntrinsicElements,
    | 'caption'
    | 'div'
    | 'span'
    | 'section'
    | 'article'
    | 'abbr'
    | 'summary'
    | 'details'
    | 'legend'
    | 'address'
    | 'figcaption'
    | 'p'
    | 'aside'
    | 'bdi'
    | 'th'
    | 'td'
    | 'cite'
    | 'code'
    | 'dt'
    | 'dd'
    | 'dfn'
    | 'del'
    | 'ins'
    | 'li'
  >;
  /** For usage with ARIA attributes */
  id?: string;
  dangerouslySetInnerHTML?: { __html: string };
  className?: never;
  'aria-hidden'?: boolean | 'true' | 'false';
}
