import { FC } from 'react';

import { buttonInnerStyle, chevronIconWrapperStyle } from './ButtonInner.css';
import { ChevronRightFatIcon } from '../../icons';
import { hasIcon } from '../../util/children';

const stitchesClassName = 'button-inner';

export const ButtonInner: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <span className={`${buttonInnerStyle} ${stitchesClassName}`}>
      {children}
      {!hasIcon(children) && (
        <span className={chevronIconWrapperStyle}>
          <ChevronRightFatIcon></ChevronRightFatIcon>
        </span>
      )}
    </span>
  );
};

ButtonInner.displayName = 'styled(ButtonInner)';
