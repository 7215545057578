import React, { forwardRef } from 'react';

import { styled } from '../../stitches.config';
import { textColors } from '../../themes/eneco/tokens';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps, createVariants } from '../../util/css/stitches';

export const StyledHeading = styled('h1', {
  fontFamily: '$heading',
  wordBreak: 'break-word',

  variants: {
    /** The visual size of the heading */
    size: {
      '3XL': { typography: '$heading3xl' },
      '2XL': { typography: '$heading2xl' },
      XL: { typography: '$headingXl' },
      L: { typography: '$headingL' },
      M: { typography: '$headingM' },
      S: { typography: '$headingS' },
      XS: { typography: '$headingXs' },
      '2XS': { typography: '$heading2xs' },
      '3XS': { typography: '$heading3xs' },
    },

    /** The color of the heading */
    color: {
      ...createVariants(textColors, 'color'),
      inherit: {
        color: 'inherit',
      },
    },
  },
  defaultVariants: {
    size: 'XL',
  },
});

const sparkyClassName = 'sparky-heading';

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ size, as, color = 'textPrimary', children, className = '', id }, ref) => {
    const variantProps = extractVariantProps({ size, color });

    return (
      <StyledHeading as={as} className={`${sparkyClassName} ${className}`} id={id} ref={ref} {...variantProps}>
        {children}
      </StyledHeading>
    );
  },
);

type HeadingVariants = TransformStitchesToSparky<typeof StyledHeading>;
export interface HeadingProps extends HeadingVariants {
  /** The DOM element to render */
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  /** For usage with ARIA attributes */
  id?: string;
  className?: never;
  children: React.ReactNode;
}

Heading.toString = () => `.${sparkyClassName}`;
Heading.displayName = 'Heading';
