import React from 'react';

import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';
import { gridData } from '../PageGrid/PageGrid';

const StyledBleed = styled('div', {
  variants: {
    /** Adds negative margins to the left and right of the child. */
    horizontal: {
      0: { marginX: '0' },
      1: { marginX: '-$1' },
      2: { marginX: '-$2' },
      3: { marginX: '-$3' },
      4: { marginX: '-$4' },
      5: { marginX: '-$5' },
      6: { marginX: '-$6' },
      8: { marginX: '-$8' },
      10: { marginX: '-$10' },
      12: { marginX: '-$12' },
      16: { marginX: '-$16' },
      24: { marginX: '-$24' },
      32: { marginX: '-$32' },

      gridGutter: {
        marginX: `-${gridData.gutters.initial}`,
        '@md': {
          marginX: `-${gridData.gutters.md}`,
        },
        '@xl': {
          marginX: 'calc(50% - 50vw + $2)',
        },
      },
    },
    /** Adds negative margins to the top and bottom of the child. */
    vertical: {
      0: { marginY: '0' },
      1: { marginY: '-$1' },
      2: { marginY: '-$2' },
      3: { marginY: '-$3' },
      4: { marginY: '-$4' },
      5: { marginY: '-$5' },
      6: { marginY: '-$6' },
      8: { marginY: '-$8' },
      10: { marginY: '-$10' },
      12: { marginY: '-$12' },
      16: { marginY: '-$16' },
      24: { marginY: '-$24' },
      32: { marginY: '-$32' },
    },
    /** Adds negative margins to the top of the child. */
    top: {
      0: { marginTop: '0' },
      1: { marginTop: '-$1' },
      2: { marginTop: '-$2' },
      3: { marginTop: '-$3' },
      4: { marginTop: '-$4' },
      5: { marginTop: '-$5' },
      6: { marginTop: '-$6' },
      8: { marginTop: '-$8' },
      10: { marginTop: '-$10' },
      12: { marginTop: '-$12' },
      16: { marginTop: '-$16' },
      24: { marginTop: '-$24' },
      32: { marginTop: '-$32' },
    },
    /** Adds negative margins to the bottom of the child. */
    bottom: {
      0: { marginBottom: '0' },
      1: { marginBottom: '-$1' },
      2: { marginBottom: '-$2' },
      3: { marginBottom: '-$3' },
      4: { marginBottom: '-$4' },
      5: { marginBottom: '-$5' },
      6: { marginBottom: '-$6' },
      8: { marginBottom: '-$8' },
      10: { marginBottom: '-$10' },
      12: { marginBottom: '-$12' },
      16: { marginBottom: '-$16' },
      24: { marginBottom: '-$24' },
      32: { marginBottom: '-$32' },
    },
    /** Adds negative margins to the left of the child. */

    left: {
      0: { marginLeft: '0' },
      1: { marginLeft: '-$1' },
      2: { marginLeft: '-$2' },
      3: { marginLeft: '-$3' },
      4: { marginLeft: '-$4' },
      5: { marginLeft: '-$5' },
      6: { marginLeft: '-$6' },
      8: { marginLeft: '-$8' },
      10: { marginLeft: '-$10' },
      12: { marginLeft: '-$12' },
      16: { marginLeft: '-$16' },
      24: { marginLeft: '-$24' },
      32: { marginLeft: '-$32' },
    },
    /** Adds negative margins to the right of the child. */
    right: {
      0: { marginRight: '0' },
      1: { marginRight: '-$1' },
      2: { marginRight: '-$2' },
      3: { marginRight: '-$3' },
      4: { marginRight: '-$4' },
      5: { marginRight: '-$5' },
      6: { marginRight: '-$6' },
      8: { marginRight: '-$8' },
      10: { marginRight: '-$10' },
      12: { marginRight: '-$12' },
      16: { marginRight: '-$16' },
      24: { marginRight: '-$24' },
      32: { marginRight: '-$32' },
    },
  },
});

type Props = TransformStitchesToSparky<typeof StyledBleed> & { className?: never };

const stitchesClassName = 'sparky-bleed';

export const Bleed: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  horizontal,
  vertical,
  top,
  bottom,
  left,
  right,
  className = '',
}) => {
  const variantProps = extractVariantProps({ horizontal, vertical, top, bottom, left, right });
  return (
    <StyledBleed {...variantProps} className={`${stitchesClassName} ${className}`}>
      {children}
    </StyledBleed>
  );
};

StyledBleed.displayName = 'styled(Bleed)';
Bleed.displayName = 'Bleed';
