import React from 'react';

import { styled } from '../../stitches.config';

export const visuallyHiddenStyle = {
  position: 'absolute',
  border: 0,
  width: 1,
  height: 1,
  padding: 0,
  margin: -1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  wordWrap: 'normal',
  inset: 0,
};

const VisuallyHiddenStyled = styled('span', visuallyHiddenStyle);
interface Props {
  children: React.ReactNode;
  id?: string;
  ariaLive?: 'polite' | 'assertive' | 'off';
}

export const VisuallyHidden = React.forwardRef<HTMLSpanElement, Props>(({ children, id, ariaLive }, ref) => {
  return (
    <VisuallyHiddenStyled ref={ref} id={id} aria-live={ariaLive}>
      {children}
    </VisuallyHiddenStyled>
  );
});

VisuallyHiddenStyled.displayName = 'styled(VisuallyHidden)';
VisuallyHidden.displayName = 'VisuallyHidden';
