import React from 'react';

import { GridItem } from './GridItem';
import { styled } from '../../stitches.config';
import { DynamicVariantProp, TransformStitchesToSparky } from '../../types';
import { extractVariantProps, extractCssProps } from '../../util/css/stitches';

const StyledGrid = styled('div', {
  display: 'grid',

  variants: {
    /*  Alignment of the gridItems on the vertical axis. */
    alignY: {
      start: {
        alignItems: 'start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'end',
      },
      stretch: {
        alignItems: 'stretch',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
    /*  Alignment of the gridItems on the horizontal axis. */
    alignX: {
      start: {
        justifyItems: 'start',
      },
      center: {
        justifyItems: 'center',
      },
      end: {
        justifyItems: 'end',
      },
      stretch: {
        justifyItems: 'stretch',
      },
    },

    justifyContent: {
      start: {
        justifyContent: 'start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'end',
      },
      stretch: {
        justifyContent: 'stretch',
      },
    },

    /*  Decides how griditems are automatically placed in the grid   */
    flow: {
      row: {
        gridAutoFlow: 'row',
      },
      column: {
        gridAutoFlow: 'column',
      },
      dense: {
        gridAutoFlow: 'dense',
      },
      rowDense: {
        gridAutoFlow: 'row dense',
      },
      columnDense: {
        gridAutoFlow: 'column dense',
      },
    },

    /*  The amount of columns your grid should get. Each column will take up 1fr. */
    columns: {
      1: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      2: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      3: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      4: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      5: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
      6: {
        gridTemplateColumns: 'repeat(6, 1fr)',
      },
      7: {
        gridTemplateColumns: 'repeat(7, 1fr)',
      },
      8: {
        gridTemplateColumns: 'repeat(8, 1fr)',
      },
      9: {
        gridTemplateColumns: 'repeat(9, 1fr)',
      },
      10: {
        gridTemplateColumns: 'repeat(10, 1fr)',
      },
      11: {
        gridTemplateColumns: 'repeat(11, 1fr)',
      },
      12: {
        gridTemplateColumns: 'repeat(12, 1fr)',
      },
    },

    /*  The space between rows and between columns. */
    gap: {
      0: { gap: '0' },
      1: { gap: '$1' },
      2: { gap: '$2' },
      3: { gap: '$3' },
      4: { gap: '$4' },
      5: { gap: '$5' },
      6: { gap: '$6' },
      8: { gap: '$8' },
      10: { gap: '$10' },
      12: { gap: '$12' },
      16: { gap: '$16' },
      24: { gap: '$24' },
      32: { gap: '$32' },
    },

    /*  The space between each row */
    rowGap: {
      0: { rowGap: '0' },
      1: { rowGap: '$1' },
      2: { rowGap: '$2' },
      3: { rowGap: '$3' },
      4: { rowGap: '$4' },
      5: { rowGap: '$5' },
      6: { rowGap: '$6' },
      8: { rowGap: '$8' },
      10: { rowGap: '$10' },
      12: { rowGap: '$12' },
      16: { rowGap: '$16' },
      24: { rowGap: '$24' },
      32: { rowGap: '$32' },
    },

    /*  The space between each column */
    columnGap: {
      0: { rowGap: '0' },
      1: { columnGap: '$1' },
      2: { columnGap: '$2' },
      3: { columnGap: '$3' },
      4: { columnGap: '$4' },
      5: { columnGap: '$5' },
      6: { columnGap: '$6' },
      8: { columnGap: '$8' },
      10: { columnGap: '$10' },
      12: { columnGap: '$12' },
      16: { columnGap: '$16' },
      24: { columnGap: '$24' },
      32: { columnGap: '$32' },
    },

    inline: {
      true: { display: 'inline-grid' },
      false: { display: 'grid' },
    },
  },
});

const sparkyClassName = 'sparky-grid';

const GridComponent = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props, ref) => {
  const {
    children,
    as = 'div',
    className = '',
    gridTemplateColumns,
    gridTemplateRows,
    gridTemplateAreas,
    ...variantProps
  } = props;

  const variants = extractVariantProps(variantProps);
  const cssProps = extractCssProps({ gridTemplateColumns, gridTemplateRows, gridTemplateAreas });
  return (
    <StyledGrid as={as} ref={ref} css={cssProps} {...variants} className={`${sparkyClassName} ${className}`}>
      {children}
    </StyledGrid>
  );
});

GridComponent.toString = () => `.${sparkyClassName}`;

type GridVariants = TransformStitchesToSparky<typeof StyledGrid>;
export interface Props extends GridVariants {
  as?: keyof JSX.IntrinsicElements;
  /** This is the [native grid-template-columns CSS property](https://www.w3schools.com/cssref/pr_grid-template-columns.asp) that will be directly passed on to the grid.   */
  gridTemplateColumns?: DynamicVariantProp<'gridTemplateColumns'>;

  /** This is the [native grid-template-rows CSS property](https://www.w3schools.com/cssref/pr_grid-template-rows.asp) that will be directly passed on to the grid.  */
  gridTemplateRows?: DynamicVariantProp<'gridTemplateRows'>;

  /** This is the [native grid-template-areas CSS property](https://www.w3schools.com/cssref/pr_grid-template-areas.asp) that will be directly passed on to the grid. */
  gridTemplateAreas?: DynamicVariantProp<'gridTemplateAreas'>;

  className?: never;
}

StyledGrid.displayName = 'styled(Grid)';
GridComponent.displayName = 'Grid';

export const Grid = Object.assign({}, GridComponent, { Item: GridItem });
