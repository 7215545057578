import React from 'react';

import ReactIs from 'react-is';

import { css, styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';

const styledCss = css({
  variants: {
    /** Hide children **above** the passed breakpoint */
    above: {
      sm: {
        '@sm': {
          display: 'none',
        },
      },
      md: {
        '@md': {
          display: 'none',
        },
      },
      lg: {
        '@lg': {
          display: 'none',
        },
      },
      xl: {
        '@xl': {
          display: 'none',
        },
      },
    },
    /** Hide children **below** the passed breakpoint */
    below: {
      sm: {
        '@smMax': {
          display: 'none',
        },
      },
      md: {
        '@mdMax': {
          display: 'none',
        },
      },
      lg: {
        '@lgMax': {
          display: 'none',
        },
      },
      xl: {
        '@xlMax': {
          display: 'none',
        },
      },
    },
  },
});

const StyledHidden = styled('div', styledCss);

type HiddenProps = TransformStitchesToSparky<typeof StyledHidden>;
type Props = { [Key in keyof HiddenProps]: Exclude<HiddenProps[Key], object> };

export const Hidden: React.FC<React.PropsWithChildren<Props>> = ({ children, above, below }) => {
  const variantProps = extractVariantProps({ above, below });
  const hiddenProps = styledCss(variantProps);

  const arrayChildren = React.Children.toArray(children);
  const hiddenChildren = React.Children.map(arrayChildren, child => {
    if (ReactIs.isElement(child)) {
      return React.cloneElement(child, { className: hiddenProps.className });
    }

    return <StyledHidden {...variantProps}>{child}</StyledHidden>;
  });

  /**
   * Because of a TS issue (https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356), we need to wrap the children in a fragment.
   * ESlint doesn't like this. But we do.
   */

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{hiddenChildren}</>;
};

StyledHidden.displayName = 'styled(Hidden)';
