import { type FC, useState, useEffect } from 'react';

import { getAspectRatioDimensions } from './utils';
import { mediaQueries } from '../../sparky.config';
import { styled } from '../../stitches.config';
import { DynamicVariantProp, MediaQueryKeys, Responsive } from '../../types';
import { extractCssProps } from '../../util/css/stitches';

type MQProp = {
  [key in MediaQueryKeys]?: string;
};

type ImageResponsive = Responsive<string>;

type ResponsiveSrcProps = {
  sizes: ImageResponsive /* Define the width of the image at breakpoints to ensure proper function of srcSet. */;
  sources?: never /* Maps to a `picture` element with `source` elements */;
  srcSet: string /* Native HTML scrSet attribute. Specify both the source and width or pixel density. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-srcset */;
};

type StaticSrcProps = {
  sizes?: never;
  sources?: MQProp /* Maps to a `picture` element with `source` elements */;
  srcSet?: never;
};

type Props = {
  alt: string;
  aspectRatio?: DynamicVariantProp<'aspectRatio'> /* Important to set to refrain document rendering from [shifting too much](https://developer.mozilla.org/en-US/docs/Learn/Performance/Multimedia#rendering_strategy_preventing_jank_when_loading_images). */;
  children?: never;
  className?: never;
  display?: DynamicVariantProp<'display'>;
  fallbackSrc?: string /* Source that will be used when any src fails to load */;
  hasLazyLoad?: boolean /* Native browser lazy loading. Not supported on older browsers. https://caniuse.com/loading-lazy-attr */;
  height?: DynamicVariantProp<'height'>;
  maxHeight?: DynamicVariantProp<'maxHeight'>;
  maxWidth?: DynamicVariantProp<'maxWidth'>;
  minHeight?: DynamicVariantProp<'minHeight'>;
  minWidth?: DynamicVariantProp<'minWidth'>;
  objectFit?: DynamicVariantProp<'objectFit'>;
  objectPosition?: DynamicVariantProp<'objectPosition'>;
  src: string;
  width?: DynamicVariantProp<'width'>;
} & (ResponsiveSrcProps | StaticSrcProps);

const StyledImage = styled('img');

// We need to map a Responsive<string> structure to `[breakpoint] [value], [...], [initialValue]`.
// { initial: '100vw', lg: '720px', xl: '1000px' } => `(min-width: 1024px) 720px, (min-width: 1440px) 1000px, 100vw`
export const sizesToString = (sizes: ImageResponsive) => {
  const keys = Object.keys(mediaQueries) as MediaQueryKeys[];
  return keys
    .filter(k => Object.keys(sizes).includes(k))
    .map(mqk => `${[mediaQueries[mqk]]} ${sizes[mqk]}`)
    .concat(sizes.initial ?? '')
    .join(', ');
};

const ownClassName = 'img-sparky';

export const Image: FC<Props> = ({
  alt,
  aspectRatio,
  className = '',
  display,
  fallbackSrc,
  hasLazyLoad = true,
  height = 'auto',
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  objectFit,
  objectPosition,
  sizes,
  sources,
  src,
  srcSet,
  width = 'auto',
}) => {
  const [hasError, setHasError] = useState(false);
  const cssProps = extractCssProps({
    aspectRatio,
    display,
    height,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    objectFit,
    objectPosition,
    width,
  });

  const aspectRatioDimensions = getAspectRatioDimensions({ aspectRatio, height, width });

  useEffect(() => {
    setHasError(false);
  }, [src, srcSet, sources]);

  function handleSrcError() {
    setHasError(true);
  }

  if (hasError && fallbackSrc) {
    return (
      <StyledImage
        alt={alt}
        className={`${ownClassName} ${className}`}
        css={cssProps}
        src={fallbackSrc}
        {...aspectRatioDimensions}
      />
    );
  }

  if (sources && Object.keys(sources).length > 0) {
    const sourceEntries = Object.entries(sources) as Array<Array<MediaQueryKeys>>;
    const sourceElements = sourceEntries.map(([key, value], i) => (
      <source key={i} media={mediaQueries[key]} srcSet={value} />
    ));

    const children = [
      ...sourceElements,
      <StyledImage
        alt={alt}
        className={`${ownClassName} ${className}`}
        css={cssProps}
        key="img"
        loading={hasLazyLoad ? 'lazy' : undefined}
        onError={handleSrcError}
        sizes={sizes ? sizesToString(sizes) : undefined}
        src={src}
        srcSet={srcSet}
        {...aspectRatioDimensions}
      />,
    ];

    return <picture>{children}</picture>;
  }

  return (
    <StyledImage
      alt={alt}
      className={`${ownClassName} ${className}`}
      css={cssProps}
      loading={hasLazyLoad ? 'lazy' : undefined}
      onError={handleSrcError}
      sizes={sizes ? sizesToString(sizes) : undefined}
      src={src}
      srcSet={srcSet}
      {...aspectRatioDimensions}
    />
  );
};
