import React from 'react';

import { RecipeVariants } from '@vanilla-extract/recipes';

import { boxRecipe } from './Box.css';
import { getClassNames } from '../../util/css';
import { BackgroundRecipeVariants, backgroundRecipe } from '../../util/css/vanilla-extract/shared/background.css';
import { BorderRadiusRecipeVariants, borderRadiusRecipe } from '../../util/css/vanilla-extract/shared/border.css';
import { PaddingRecipeVariants, paddingRecipe } from '../../util/css/vanilla-extract/shared/padding.css';

const sparkyBoxClassName = 'sparky-box';

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      as = 'div',
      backgroundColor,
      borderRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      children,
      className = '',
      id,
      overflow,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingX,
      paddingY,
    },
    ref,
  ) => {
    const classNames = getClassNames(
      sparkyBoxClassName,
      backgroundRecipe({ backgroundColor }),
      boxRecipe({ overflow }),
      paddingRecipe({ padding, paddingBottom, paddingLeft, paddingRight, paddingTop, paddingX, paddingY }),
      borderRadiusRecipe({
        borderRadius,
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
      }),
      className,
    );

    const TagName = as;

    return (
      <TagName className={classNames} id={id} ref={ref}>
        {children}
      </TagName>
    );
  },
);

Box.toString = () => `.${sparkyBoxClassName}`;
Box.displayName = 'Box';

type BoxProps = RecipeVariants<typeof boxRecipe> &
  BackgroundRecipeVariants &
  BorderRadiusRecipeVariants &
  PaddingRecipeVariants & {
    /** The DOM element to render*/
    as?: keyof JSX.IntrinsicElements;
    children: React.ReactNode;
    className?: string;
    id?: string;
  };
